import { useEffect, useState } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useAtomValue, useAtom, useSetAtom } from 'jotai'
import {
  formatDateTime,
  generateRandomAvatarColor,
  getNameAbbreviation,
} from '@/lib/utils'
import { RequestAtom, choosenRequestIDAtom } from '@/atom/request'
import { getProjectRequest } from '@/services/api-client'
import { toast } from 'sonner'
import { useNavigate } from 'react-router-dom'
import { ProjectRequest as IProjectRequest } from '@/types/IProjectRequest'

import { DataTable } from '@/components/request/DataTable'
import ClipboardCopyIcon from '@/components/icons/ClipboardCopyIcon'
import EyeOpenIcon from '@/components/icons/Eye'
import NotAllowedIcon from '@/components/icons/NotAllowedIcon'
import SortIcon from '@/components/icons/SortIcon'
import VerticalDot from '@/components/icons/VerticalDot'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import RequestDetails from '@/components/request/request-details.tsx'
import PenIcon from '@/components/icons/PenIcon'
import EditRequestForm from '@/components/request/request-form/edit-form'
import { Archive, CheckCircle2Icon, LoaderCircleIcon } from 'lucide-react'
import { CrossCircledIcon } from '@radix-ui/react-icons'
import { Separator } from '@/components/ui/separator'
import ActionForm from '@/components/request/request-form/action-form'
import useDirOwnerProdAccess from '@/hooks/use-dir-owner-prod-access'
import { isOpenEditModalAtom, requestToEditId } from '@/atom/request'
import { PRIORITY_ICON, STATUS_COLOR } from '@/configs/constants'

type RequestListType = {
  id: string
  subject: string
  creationDate: string
  status: string
  author: string
  id_subject: string
  actions: string
  priority: string
}

export default function Request() {
  const { hasAccess } = useDirOwnerProdAccess()

  const columns: ColumnDef<RequestListType>[] = [
    {
      accessorKey: 'id_subject',
      header: 'Clé - Sujet',
      cell: ({ row }) => {
        return (
          <span className="text-primary-dark text-sm font-semibold">
            {row.getValue('id_subject')}
          </span>
        )
      },
    },
    {
      accessorKey: 'subject',
      cell: ({ row }) => {
        const _subject: string = row.getValue('subject')
        const subject = _subject.split('__split__')[0]
        const category = _subject.split('__split__')[1]
        const { bgColor } = generateRandomAvatarColor(
          category + 'category_color'
        )
        return (
          <div className="flex flex-col gap-y-2">
            <div
              aria-hidden
              onClick={() => setCurrentRequestKey(row.getValue('action'))}
              className="transition-all flex gap-x-2 items-center group text-primary-dark w-[600px] hover:cursor-pointer hover:underline overflow-hidden text-ellipsis text-sm font-medium"
            >
              <span className="line-clamp-2 overflow-hidden">{subject}</span>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger id={'view-' + subject}>
                    <EyeOpenIcon className="hidden transition-all group-hover:block" />
                  </TooltipTrigger>
                  <TooltipContent side="bottom" sideOffset={10}>
                    <p className="relative">
                      <span className="" /> Voir le ticket
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <div className="flex items-baseline gap-x-1">
              <div
                className="w-3 h-3 rounded-sm"
                style={{ backgroundColor: bgColor }}
              />
              <span className="text-sm text-neutral-darker/80 font-normal">
                {category}
              </span>
            </div>
          </div>
        )
      },
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Sujet
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
    },
    {
      accessorKey: 'priority',
      header: 'Priorité',
      cell: ({ row }) => {
        const prio: string = row.getValue('priority')
        return (
          <span className="text-primary-dark text-sm font-semibold">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>{PRIORITY_ICON[prio]}</TooltipTrigger>
                <TooltipContent side="bottom" sideOffset={10}>
                  <p className="relative">
                    <span className="" /> {prio}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </span>
        )
      },
    },
    {
      accessorKey: 'author',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Auteur
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        /**
         * concat value on one string will be needed for sorting system
         */
        const author: string = row.getValue('author')
        const name = `${author.split(' ')[0]} ${author.split(' ')[1]}`
        const email = `${author.split(' ')[2]}`
        const { bgColor, textColor } = generateRandomAvatarColor(name)
        return (
          <span className="text-primary-dark text-sm font-medium flex items-center gap-x-2">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <span
                    className="flex justify-center items-center rounded-full w-7 h-7 text-xs font-medium hover:cursor-default"
                    style={{ backgroundColor: bgColor, color: textColor }}
                  >
                    {getNameAbbreviation(name)}
                  </span>
                </TooltipTrigger>
                <TooltipContent side="bottom" sideOffset={10}>
                  <p className="relative">
                    <span className="" /> {email}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </span>
        )
      },
    },
    {
      accessorKey: 'creationDate',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Date
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        const formattedDate = formatDateTime(
          new Date(row.getValue('creationDate'))
        )
        const date = formattedDate.split(' ')[0]
        const time = formattedDate.split(' ')[1]
        return (
          <div className="flex flex-col">
            <span className="text-primary-dark font-normal text-sm">
              {date}
            </span>
            <span className="text-neutral-darker font-normal text-sm">
              {time}
            </span>
          </div>
        )
      },
    },
    {
      accessorKey: 'id',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Ticket Jira
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        if (row.getValue('id')) {
          return (
            <button
              onClick={() => navigate(`/request/${row.getValue('id')}`)}
              className="text-sm font-semibold bg-neutral-light text-neutral-darker/80 hover:text-neutral-darker/100 rounded-full px-3 py-2 underline hover:cursor-pointer"
            >
              {row.getValue('id')}
            </button>
          )
        }
        return <NotAllowedIcon />
      },
    },
    {
      accessorKey: 'status',
      header: ({ column }) => {
        return (
          <div className="flex items-center gap-x-2">
            Statut
            <SortIcon
              className="hover:cursor-pointer"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            />
          </div>
        )
      },
      cell: ({ row }) => {
        const status: string = row.getValue('status')
        const { bgColor } = generateRandomAvatarColor(
          'color_offset_pre' + row.getValue('status')
        )
        const { bgColor: bgColorTransparency } = generateRandomAvatarColor(
          'color_offset_pre' + row.getValue('status'),
          0.15
        )
        return (
          <span className="text-sm font-medium flex items-center gap-x-2">
            <span
              className="flex text-xs uppercase justify-center items-center font-medium px-3 py-1 rounded-md"
              style={{
                backgroundColor: STATUS_COLOR[status] + '20',
                color: STATUS_COLOR[status],
              }}
            >
              {status}
            </span>
          </span>
        )
      },
    },
    {
      accessorKey: 'action',
      id: 'action',
      enableHiding: false,
      header: '',
      cell: ({ row }) => {
        const ALLOWED_STATUS = 'en attente'
        const status: string = row.getValue('status')
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              {status !== 'Archivée' ? (
                <Button
                  variant="ghost"
                  className="h-8 w-8 p-0 border border-neutral-dark rounded-lg"
                  id="more-option-btn"
                >
                  <span className="sr-only">Open menu</span>
                  <VerticalDot />
                </Button>
              ) : (
                ''
              )}
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="border-neutral-dark w-52 shadow-sm p-4 [&>div]:py-1 [&>div]:px-2 flex flex-col gap-2"
            >
              {status !== 'Rejetée' ? (
                <>
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    onClick={() => setCurrentRequestKey(row.getValue('action'))}
                    id="view-btn"
                  >
                    <span className="flex gap-2 text-base font-normal items-center">
                      <EyeOpenIcon /> Voir
                    </span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    id="copy-btn"
                    className="hover:cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.origin}/request/${row.getValue('action')}`
                      )
                      toast('Lien copié dans le presse-papiers')
                    }}
                  >
                    <span className="flex gap-2 text-base font-normal items-center">
                      <ClipboardCopyIcon /> Copier le lien
                    </span>
                  </DropdownMenuItem>
                </>
              ) : (
                ''
              )}
              {ALLOWED_STATUS === status.toLowerCase() ? (
                <DropdownMenuItem
                  id="edit-btn"
                  className="hover:cursor-pointer"
                  onClick={() =>
                    setCurrentRequestKeyToEdit(row.getValue('action'))
                  }
                >
                  <span className="flex gap-2 text-base font-normal items-center">
                    <PenIcon /> Modifier
                  </span>
                </DropdownMenuItem>
              ) : (
                ''
              )}
              {hasAccess ? (
                <>
                  {status === 'Acceptée' ? (
                    <DropdownMenuItem
                      id="resolve-btn"
                      className="hover:cursor-pointer"
                      onClick={() =>
                        hanldeAction('resolve', row.getValue('action'))
                      }
                    >
                      <span className="flex gap-2 text-base font-normal items-center">
                        <CheckCircle2Icon className="w-4 h-4" /> Résolue
                      </span>
                    </DropdownMenuItem>
                  ) : status !== 'Résolue' &&
                    status !== 'Refuser' &&
                    status !== 'Rejetée' ? (
                    <>
                      <DropdownMenuItem
                        id="accept-btn"
                        className="hover:cursor-pointer"
                        onClick={() =>
                          hanldeAction('accept', row.getValue('action'))
                        }
                      >
                        <span className="flex gap-2 text-base font-normal items-center">
                          <CheckCircle2Icon className="w-4 h-4" /> Accepter
                        </span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        id="denie-btn"
                        className="hover:cursor-pointer"
                        onClick={() =>
                          hanldeAction('denie', row.getValue('action'))
                        }
                      >
                        <span className="flex gap-2 text-base font-normal items-center">
                          <CrossCircledIcon className="w-4 h-4" /> Refuser
                        </span>
                      </DropdownMenuItem>
                    </>
                  ) : (
                    ''
                  )}
                  <DropdownMenuItem
                    id="archive-btn"
                    className="hover:cursor-pointer"
                    onClick={() =>
                      hanldeAction('delete', row.getValue('action'))
                    }
                  >
                    <span className="flex gap-2 text-base font-normal items-center">
                      <Archive className="w-4 h-4" /> Archiver
                    </span>
                  </DropdownMenuItem>
                </>
              ) : (
                ''
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )
      },
    },
  ]

  const [isInteractOutside, setIsInteractOutside] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useAtom(isOpenEditModalAtom)
  const editKeyFromFullScreen = useAtomValue(requestToEditId)
  const [_, setRequestAtom] = useAtom(RequestAtom)
  const [currentRequestKey, setCurrentRequestKey] = useState<string>()
  const [currentRequestKeyToEdit, setCurrentRequestKeyToEdit] =
    useState<string>()
  const [requestList, setRequestList] = useState<RequestListType[]>([])
  const [apiResult, setApiResult] = useState<IProjectRequest[]>()
  const navigate = useNavigate()
  const [editFormAction, setEditFormAction] = useState<{
    title: string
    isEdit: boolean
  }>()
  const [isLoading, setIsLoading] = useState(true)
  const [isOpenActionModal, setIsOpenActionModal] = useState(false)
  const setChoosenRequestIDAtom = useSetAtom(choosenRequestIDAtom)

  const hanldeAction = (type: string, selectedKey: string) => {
    setChoosenRequestIDAtom({ actionType: type, id: selectedKey })
    setIsOpenActionModal(true)
  }

  useEffect(() => {
    const filteredRequests = filterCurrentRequest(
      currentRequestKey ?? (currentRequestKeyToEdit as string)
    )

    setRequestAtom(filteredRequests as IProjectRequest)
  }, [currentRequestKey, currentRequestKeyToEdit])

  useEffect(() => {
    if (editKeyFromFullScreen) {
      const filteredRequests = filterCurrentRequest(editKeyFromFullScreen)
      setCurrentRequestKeyToEdit(editKeyFromFullScreen)
      setRequestAtom(filteredRequests as IProjectRequest)
    }
  }, [apiResult])

  async function getProjectRequests() {
    const { data } = await getProjectRequest()
    return Promise.resolve(data)
  }

  async function fetchData() {
    try {
      const result = await getProjectRequests()
      const filteredData = result
      // .filter((item: IProjectRequest) => item.status !== 'Archivée')
      // .filter((item: IProjectRequest) => item.status !== 'Résolue')
      const mappedResult = [
        ...filteredData.map((item: IProjectRequest) => ({
          id: item.data.jira_issue_id && '',
          subject: `${item.title}__split__${item.category}`,
          creationDate: item.created_at,
          status: item.status,
          author: `${item.submitted_by.first_name} ${item.submitted_by.last_name} ${item.submitted_by.email}`,
          id_subject: `${item.data.jira_issue_id && ''} ${item.title}`,
          action: item.id,
          priority: item.priority,
        })),
      ]
      setApiResult(filteredData)
      setRequestList(() => [...mappedResult])
    } catch (error) {
      throw new Error('Unexpected error occured')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  function filterCurrentRequest(key: string): IProjectRequest | undefined {
    return apiResult?.find((item) => key === item.id)
  }

  function onCreateDetailsOpenChange() {
    setCurrentRequestKey(undefined)
    setCurrentRequestKeyToEdit(undefined)
  }

  if (isLoading) {
    return (
      <div className="h-full flex items-center w-full justify-center">
        <LoaderCircleIcon className="animate-spin w-8 h-8" />
      </div>
    )
  }

  return (
    <>
      <DataTable
        editFormAction={editFormAction}
        columns={columns}
        data={requestList}
        apiResult={apiResult}
        fetchData={fetchData}
      />
      <Dialog
        open={!!currentRequestKey}
        onOpenChange={onCreateDetailsOpenChange}
      >
        <DialogContent className="max-w-[768px]">
          <RequestDetails onClose={onCreateDetailsOpenChange} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!currentRequestKeyToEdit || isOpenEditModal}
        onOpenChange={() => {
          setCurrentRequestKeyToEdit(undefined)
        }}
      >
        <DialogContent
          onInteractOutside={(e) => {
            e.preventDefault()
            setIsInteractOutside(true)
          }}
          className="max-w-[768px] pb-0"
        >
          <EditRequestForm
            isInteractOutside={isInteractOutside}
            closeModal={(params?: { title: string; isEdit: boolean }) => {
              setCurrentRequestKeyToEdit(undefined)
              setEditFormAction(params)
              setIsOpenEditModal(false)
              setIsInteractOutside(false)
            }}
          />
        </DialogContent>
      </Dialog>

      {/* ACTION CONFIRMATION */}
      <Dialog
        open={!!isOpenActionModal}
        onOpenChange={() => setIsOpenActionModal(false)}
      >
        <DialogContent className="max-w-[480px] max-h-[600px]">
          <ActionForm onCancel={() => setIsOpenActionModal(false)} onActionSuccess={() => fetchData()} />
        </DialogContent>
      </Dialog>
    </>
  )
}
