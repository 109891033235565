import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const MarkdownRenderer = ({
  content,
  dark,
}: {
  content: any
  dark?: boolean
}) => {

  function convertMarkdownLinksToImages(markdown: string) {
    return markdown.replace(/<https?:\/\/[^\s<>]+?\.jpeg>/g, (match) => {
      const url = match.slice(1, -1).replace("https://preprod.elephant-track.com", "");
      return `![${url}](${url})`;
    });
  }
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      className={`prose ${dark ? 'text-gray-300' : ''} [&_table]:border [&_td]:border [&_th]:border [&_td]:p-3 [&_th]:p-3 [&_ol]:list-decimal [&_ul]:list-disc [&_h2]:mt-4 [&_h2]:mb-2 [&_h1]:mt-4 [&_h1]:mb-2 [&_hr]:mt-2`}
    >
      {convertMarkdownLinksToImages(content)}
    </ReactMarkdown>
  )
}

export default MarkdownRenderer
