import { clsx, type ClassValue } from 'clsx'
import { fromADF } from 'mdast-util-from-adf'
import { toMarkdown } from 'mdast-util-to-markdown'
import { twMerge } from 'tailwind-merge'
import { formatDistanceToNow } from 'date-fns'
import { fr } from 'date-fns/locale'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

/**
 *
 * @param displayName string
 * @returns Get the first letters of name
 */
export function getNameAbbreviation(displayName: string): string {
  const [first, second] = displayName.split(' ')
  if (second) {
    return `${first[0]}${second[0]}`.toUpperCase()
  } else {
    return `${first[0]}${first[1]}`.toUpperCase()
  }
}

export function generateRandomAvatarColor(
  displayName: string,
  transparency: number = 1
): {
  bgColor: string
  textColor: string
} {
  if (displayName) {
    let textColor = '#FFFFFF'
    const getHashOfString = (str: string) => {
      let hash = 0
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      hash = Math.abs(hash)
      return hash
    }
    const normalizeHash = (hash: number, min: number, max: number) => {
      return Math.floor((hash % (max - min)) + min)
    }

    const hRange = [
      [0, 300],
      [0, 240],
    ]
    const sRange = [50, 100]
    const lRange = [30, 80]

    const generateHSL = (name: string) => {
      const hash = getHashOfString(name)
      let h = normalizeHash(hash, hRange[0][0], hRange[0][1])

      if (h >= 300) {
        h = normalizeHash(hash, hRange[1][0], hRange[1][1])
      }

      const s = normalizeHash(hash, sRange[0], sRange[1])
      const l = normalizeHash(hash, lRange[0], lRange[1])
      textColor = l < 50 ? '#ffffff' : '#000000'
      return [h, s, l]
    }

    const HSLtoString = (hsl: number[]) => {
      return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%, ${transparency})`
    }

    return { bgColor: HSLtoString(generateHSL(displayName)), textColor }
  } else {
    return {
      bgColor: '',
      textColor: '',
    }
  }
}

/**
 *
 * @param date Date
 * @returns formatted string 25/01/2024 15:14
 */
export function formatDateTime(date: Date) {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')

  return `${day}/${month}/${year} ${hours}:${minutes}`
}

/**
 *
 * @param dateString
 * @returns formatted string 14 juill.
 */
export function formatDate(dateString: string): string {
  const date = new Date(dateString)

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }

  const formatter = new Intl.DateTimeFormat('fr-FR', options)
  return formatter.format(date)
}

function concatenateTextFields(obj: any) {
  function handleType(type: string) {
    let result = ''
    const typesToCheck = ['paragraph', 'table', 'tableRow', 'bulletList']
    if (typesToCheck.includes(type)) {
      result += '\n'
    }
    if (type === 'table' || type === 'tableRow') {
      result += '\n -----'
    }
    if (type === 'tableCell') {
      result += ' | '
    }
    if (type === 'listItem') {
      result += '\n\n ->'
    }
    return result
  }
  if (typeof obj !== 'object') return ''

  let result = ''
  for (let key in obj) {
    if (key === 'text') {
      result += obj[key]
    } else if (key === 'type') {
      result += handleType(obj[key])
    } else {
      result += concatenateTextFields(obj[key])
    }
  }
  return result
}

/**
 * @purpose handle ADF format for front view
 * @param text ADF format or flat text
 * @returns markdown format
 */
export const JiraTextFormatAdapter = (text: any): any => {
  let description = ''
  try {
    const tree = fromADF(text)
    description = toMarkdown(tree as any)
    description = description.replace(/\\/g, '\n')
    description = description.replace(/&#x20;/g, '\n')
    description = description.replace(/“ \*/g, '“*')
    description = description.replace(/” \*/g, '”*')
    description = description.replace(/→/g, '\n→')
  } catch (error: any) {
    description = adfTableToMarkdown(text)
    // description = concatenateTextFields(text)
  }
  return description
}

function adfTableToMarkdown(adf: any) {
  let markdown = '';
  if (adf && adf.content.length) {
    adf.content.forEach((item: any) => {
      if (item.type === "table") {
        console.log('first', item)
        const content = item?.content; // Get the table rows
        content.forEach((row: any, rowIndex: number) => {
          if (row.type === "tableRow") {
            let rowMarkdown = '| ';
            row.content.forEach((cell: any) => {
              const cellText = cell.content?.[0]?.content?.[0]?.text || "";
              rowMarkdown += `${cellText} | `;
            });
      
            markdown += rowMarkdown.trim() + '\n';
            if (rowIndex === 0) {
              markdown += '| ' + row.content.map(() => '---').join(' | ') + ' |\n';
            }
          }
        });
      } else {
        if (item.content) {
          const tree = fromADF({
            type: "doc",
            version: 1,
            content: item.content
          })
          let description = toMarkdown(tree as any)
          description = description.replace(/\\/g, '\n')
          description = description.replace(/&#x20;/g, '\n')
          description = description.replace(/“ \*/g, '“*')
          description = description.replace(/” \*/g, '”*')
          description = description.replace(/→/g, '\n→')
          markdown += description + '\n\n'
        }
      }
    })
  }
  return markdown;
}

// Utility function to format elapsed time based on the timestamp
export const formatTimeElapsed = (timestamp: string | Date): string => {
  const now = new Date() // Get the current date and time
  const timeDifference = now.getTime() - new Date(timestamp).getTime() // Calculate the time difference in milliseconds

  // If more than 24 hours have passed, return the formatted date and time
  if (timeDifference > 24 * 60 * 60 * 1000) {
    return new Date(timestamp).toLocaleString('fr-FR', {
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
  }

  // Otherwise, return the relative time format (e.g., "5 minutes ago")
  return formatDistanceToNow(new Date(timestamp), {
    addSuffix: true,
    locale: fr,
  })
}

export function extractStringEpic(input: string = ''): string {
  try {
    const regex = /Module\s+"([^"]+)"/
    const match = RegExp(regex).exec(input)

    if (match) {
      return match[1]
    } else {
      return input
    }
  } catch (error) {
    return ''
  }
}

export function splitDates(startDate: string, endDate: string, parts: number) {
  const start: any = new Date(startDate)
  const end: any = new Date(endDate)
  const dates = []

  // Calculate the interval in milliseconds
  const interval = (end - start) / (parts - 1)

  for (let i = 0; i < parts; i++) {
    // Add each interval to the start date to get the next date
    const date = new Date(start.getTime() + interval * i)
    dates.push(date)
  }

  return dates
}

export function classifyFile(file: File): Record<string, boolean> {
  return {
    picture: file.type.startsWith('image/'),
    video: file.type.startsWith('video/'),
    pdf: file.name.toLowerCase().endsWith('.pdf'),
    other:
      !['image/', 'video/'].some((type) => file.type.startsWith(type)) &&
      !file.name.toLowerCase().endsWith('.pdf'),
  }
}

type _VALID_TYPE = "image" | "video" | "document" | ""
export function getFileType(url: any): _VALID_TYPE {
  // Define file type categories and their extensions
  const fileTypes = {
    image: ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp'],
    video: ['.mp4', '.avi', '.mkv', '.mov', '.wmv', '.flv', '.webm'],
    document: [
      '.pdf',
      '.doc',
      '.docx',
      '.xls',
      '.xlsx',
      '.ppt',
      '.pptx',
      '.txt',
      '.csv',
    ],
  }

  // Extract the file extension from the URL
  const extension = url.split('.').pop().toLowerCase()

  // Check which category the extension belongs to
  for (const [type, extensions] of Object.entries(fileTypes)) {
    if (extensions.includes(`.${extension}`)) {
      return type as _VALID_TYPE // Return the type (image, video, or document)
    }
  }

  return '' // If no match is found
}
